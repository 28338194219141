import store from "@/store";
import { MarginUnitType } from "@/views/journals/enums";

export function getMarginTitle() {
  return marginTitle(isMarginUnitPercent(), "+");
}

export function getMarginMinTitle() {
  return marginTitle(isMarginUnitPercent(), "-");
}

export function getMarginResultTitle(planId: number) {
  return marginTitle(isMarginResultUnitPercent(planId), "+");
}

export function getMarginMinResultTitle(planId: number) {
  return marginTitle(isMarginResultUnitPercent(planId), "-");
}

export function isMarginUnitPercent() {
  const settings = (store.state as any).settings.settings;
  return settings.marginUnitType === MarginUnitType.Percent;
}

export function isMarginResultUnitPercent(planId: number) {
  const planSettings =
    (store.state as any).planSettings.planSettings[planId] ?? {};
  return planSettings.marginResultUnitType === MarginUnitType.Percent;
}

function marginTitle(isMarginUnitPercent: boolean, sign: string) {
  let marginUnit = "м³";
  if (isMarginUnitPercent) marginUnit = "%";
  return `Марджин. (${sign}) (${marginUnit})`;
}
